@font-face {
    font-family: 'Apercu Pro';
    src: url('ApercuPro-Regular.woff2');
    src: local('Apercu Pro Regular'), local('ApercuPro-Regular'),
        url('ApercuPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Apercu Pro';
    src: url('ApercuPro-Italic.woff2');
    src: local('Apercu Pro Italic'), local('ApercuPro-Italic'),
        url('ApercuPro-Italic.woff2') format('woff2'),
        url('ApercuPro-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Apercu Pro';
    src: url('ApercuPro-Light.woff2');
    src: local('Apercu Pro Light'), local('ApercuPro-Light'),
        url('ApercuPro-Light.woff2') format('woff2'),
        url('ApercuPro-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Apercu Pro';
    src: url('ApercuPro-LightItalic.woff2');
    src: local('Apercu Pro Light Italic'), local('ApercuPro-LightItalic'),
        url('ApercuPro-LightItalic.woff2') format('woff2'),
        url('ApercuPro-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

